
import React, { Component } from 'react'

class Nav extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);

    this.state = {
      links: [
        'about_me',
        'posts',
        'contact'
      ]
    }
  }

  handleClick(e) {

  }

  render() {
    return (
        <nav>
          <p className="help">" Press ? for help</p>
          <p>.. (up a dir)</p>
          <p className="red">/Users/ellbot/website/nav/</p>
          <ul>
            <li>
            <a href="#" onClick={this.props.pageChangeHandler} page="about_me">about_me</a>
            </li>
            <li>
              <a href="#" onClick={this.props.pageChangeHandler} page="posts">posts</a>
            </li>
            <li>
              <a href="#" onClick={this.props.pageChangeHandler} page="contact">contact</a>
            </li>
          </ul>
        </nav>
    )
  }
}

export default Nav
