import React, { Component } from 'react';
import './App.css';
import Nav from './Nav';
import Page from './Page';

class App extends Component {
  constructor(props) {
    super(props);

    this.handleKeyPress = this.handleKeyPress.bind(this);

    this.state = {
      isInHelpMode: false,
      currentPage: "about_me"
    };
  }

  componentDidMount() {
    window.addEventListener("keypress", this.handleKeyPress);
  }
  componentWillUnmount() {
    window.removeEventListener("keypress", this.handleKeyPress);
  }

  handlePageChange = (event) => {
    this.setState({ currentPage: event.target.attributes.page.value})
  }

  handleKeyPress(e) {
    e.preventDefault();
    console.log(e.keyCode);
    if (e.keyCode === 63) {
      this.setState(state => ({
        isInHelpMode: !state.isInHelpMode
      }));
    }
  }

  render() {
    return (
      <div className="app">
        <Nav pageChangeHandler={this.handlePageChange}></Nav>
        <section className="right">
          <Page currentPage={this.state.currentPage}></Page>
        </section>
      </div>
    );
  }
}

export default App;
